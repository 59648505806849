<template>
	<div :key="key">
		<div class="booking-tabs">
			<div class="booking-toolbar ">
				<div class="md-booking-toolbar" style="display: flex; flex-direction: row; flex-wrap: nowrap; justify-content: flex-start; align-items:  center; align-content: center; height: 50px; background-color: #fff" >
					<div class="" style="width: 40px; text-align: center" >
						<router-link to="/calendario" style="padding: 10px 10px 10px 2px" >
							<md-icon>today</md-icon>
						</router-link>
					</div>
					<div class="" style="flex-grow:  1; ">
						<md-tabs md-swipeable  md-sync-route>
							<md-tab md-tabs id="detalles" :md-label="lang.detalles" :to="{name:'detalles'}"></md-tab>
							<md-tab md-tabs id="cargos" :md-label="lang.cargos" :to="{name:'cargos'}"></md-tab>
							<md-tab md-tabs id="historial" :md-label="lang.historial" :to="{name:'historial'}"></md-tab>
						</md-tabs>
					</div>
					<div v-if="(booking.detallesBasicos)">
						<md-menu md-size="big" md-direction="bottom-end" v-if="(booking.detallesBasicos[0].estatus == 'provisional' && !booking.detallesBasicos[0].checkin_realizado)">
							<md-button class="md-icon-button" md-menu-trigger>
								<md-icon>more_vert</md-icon>
							</md-button>

							<md-menu-content >
								<md-menu-item @click="toConfirmada" v-if="(booking.detallesBasicos[0].confirmada != '1')">
									<span>{{lang.establecer_confirmada}} </span>
									<md-icon>done_all</md-icon>
								</md-menu-item>

								<md-menu-item v-else @click="toProvisional">
									<span>{{lang.establecer_provicional}}</span>
									<md-icon>watch_later</md-icon>
								</md-menu-item>
							</md-menu-content>

						</md-menu>
					</div>
				</div>
			</div>
			<md-content class="tab-content">
				<router-view/>
			</md-content>
		</div>
	</div>
</template>
<script>
	import {request, toFormData} from "../../helpers";
	import Header from "../Header";
	export default {
		components: {Header},
		props   : ['id'],
		name    : 'md-calendario-booking',
		computed: {
			lang() {return this.$store.state.lang},
			booking() {return this.$store.state.booking}
		},
		methods:{
			async toProvisional(){
				let r = await request(base_url+'/setProvisionalDate?view=json&jwt='+this.$store.state.jwt, toFormData({idReserva : this.$route.params.id}));
				this.$store.commit('setBooking',{});
				await this.$store.dispatch('getBooking',{id:this.$route.params.id});
				this.$store.state.keys.booking +=1;
				this.key +=1;
			},
			async toConfirmada(){
				let r =  await request(base_url+'/cambiarestadoconfprov?view=json&jwt='+this.$store.state.jwt, toFormData({idReserva : this.$route.params.id, inputConfProv : 1}));
				this.$store.commit('setBooking',{});
				await this.$store.dispatch('getBooking',{id:this.$route.params.id});
				this.key +=1;
			}
		},
		async beforeMount(){
			this.$store.state.loading = true;
			this.$store.commit('setBooking',{});
			await this.$store.dispatch('getBooking',{id:this.$route.params.id});
			this.$store.commit('setTitle', this.lang.reserva);
		},
		mounted() {
		},

		data() {
			return {
				key :0
			}
		}
	}
</script>
